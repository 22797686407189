import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import ContactForm from "./contact/ContactForm";


class ServicePcb extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);

        
    }
    
    render(){
        return(
            <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                 style={{ 
                    backgroundColor: '#5255FF', 
                    display: 'flex',
                    backgroundPosition: 'top', 
                    alignItems: 'center',
                    position: 'relative', 
                }}>
                
                <div style={{transform: 'scale(0.8)',}} className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-lg-7 about_hero_content">
                            <h2>PCB & PCBA Service </h2>
                            <p>One-stop solution for IoT, Robotics, Electronics motherboards, printed circuit boards (PCB), and printed circuit board assembly (PCBA)</p>
                        </div>
                        
                        <div className="col-lg-5 about_hero_image" style={{alignSelf: 'flex-end'}}>
                            <img src="/assets/images/service/pcb_pcba/Hero.webp"/>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--80 bg_color--1 service-pcb">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30}}>
                                        <div className="col-lg-8" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">ANTT Robotics Provides High-Tech PCB Design Services</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/pcb_pcba/1.webp"/>
                                        <p className="description">ANTT Robotics is a leading provider of high-tech printed circuit board (PCB) design services. Their team of experts specializes in embedded engineering and has vast experience in embedded system design and production systems. ANTT Robotics designs the circuit, prototypes it, and then designs the PCB layout for your project, providing a complete solution for turning your concept or idea into a working product.</p>
                                      
                                    </div>
                                    {/* End Single Area */}
                                    
                                    {/* Start Embedded Video Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120 }}>
                                        <h2 className="title">Watch Our PCB Service Overview</h2>

                                        {/* Embed YouTube Video */}
                                        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, width: '100%', overflow: 'hidden', maxWidth: '800px', backgroundColor: '#000' }}>
                                            <iframe
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                                src="https://www.youtube.com/embed/YsIl_axt_mo"
                                                title="PCB Service Overview"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </div>
                                    </div>
                                    {/* End Embedded Video Area */}

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <div className="col-lg-9" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">Complete PCB Solutions: ANTT Robotics' Expertise and Manufacturing Capabilities</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/pcb_pcba/2.webp"/>
                                        <p className="description">Manufacturing process for PCBs, from SMD to through-hole and mixed assembly projects. They offer free Valor DFM/DFA checks and functional testing to ensure the quality of your boards, with no minimum cost constraints or additional tooling expenses when you repurchase. By handling everything in-house, ANTT Robotics can ensure high-quality PCBs that meet your specifications and are immediately sent to their assembly department.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <div className="col-lg-10" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">From Design to Manufacturing: ANTT Robotics Offers Comprehensive Tech Solutions</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/pcb_pcba/3.webp"/>
                                        <p>In addition to PCB design and manufacturing, ANTT Robotics offers a range of services to meet their clients' needs. Their team is experienced in programming for STM32, ESP32, and Arduino, as well as MATLAB modeling, Multisim and Proteus simulation, power electronics, machine learning model training, FEM, motor controls, and enclosure design. This diverse range of expertise allows ANTT Robotics to provide a comprehensive solution for their clients' tech needs.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                        

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <div className="col-lg-10" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">Expert PCB Design Services for Your Unique Project Needs</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/pcb_pcba/4.webp"/>
                                        <p>Our team has vast experience in embedded system design, including the design of a PCB to minimize EMI and EMC. We can handle various interfaces, such as HDMI, MIPI DSI/CSI, I2S, UART, USB, SPI, I2C, SDIO, PCIe, Wi-Fi, BLE, BT, GSM/GPRS, LTE, and CAT-M1, ensuring that we meet your unique project needs.</p>

                                        <p>Partnering with ANTT Robotics means that you can be confident that your PCBs will be immediately sent to our assembly department. We manage the entire manufacturing process, from SMD to through-hole and mixed assembly projects, as well as providing free Valor DFM/DFA checks and functional testing to ensure the quality of your boards. Trust us to turn your concept or idea into a working product with our expert PCB design services.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    <div className="rn-contact-us ptb--30" id="contact">
                                        <div className="contact-form--1">
                                            
                                            <div className="row row--35" style={{alignItems: 'stretch', marginTop: 120}}>
                                                <div className="col-12 order-2 order-lg-1">
                                                    <div className="text-left mb--50">
                                                        <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                                        <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                                        <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                    </div>
                                                    
                                                    <div className="form-wrapper">
                                                        <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '600px' }}></div>
                                                        {/* <ContactForm /> */}
                                                        
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 order-1 order-lg-2">
                                                    <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                        <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* End Contact Us */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={0}  />

        </React.Fragment>
        )
    }
}
export default ServicePcb;